import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Bath from "../assets/img/bath.png";
import Bed from "../assets/img/bed.png";
import NoImage from "../assets/img/image-not.jpg";
import { liveUrl } from "../common/component/url";
import Navbar from "../common/component/navbar";
import BottomBar from "../common/component/bottomBar";
import Searching from "../common/component/searching";
import OurServices from "../common/component/ourServices";
export default function RentDetails() {
  const Navigate = useNavigate();
  let params = useParams();
  const id = params?.id.split("-")[1];
  const [newData, setNewData] = useState([]);
  const [loader, setLoader] = useState(false);
  const handleSubmit = () => {
    setLoader(true);
    fetch(`${liveUrl}rent`, {
      body: JSON.stringify({
        id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setNewData(data.result);
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    handleSubmit();
  }, []);
  const formatBudget = (value) => {
    const formattedValue = value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    if (value >= 10000000) {
      return (
        (value / 10000000).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) + " Crore"
      );
    } else if (value >= 100000) {
      return (
        (value / 100000).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) + " Lac"
      );
    } else if (value >= 1000) {
      return (
        (value / 1000).toLocaleString(undefined, { minimumFractionDigits: 2 }) +
        " Thousand"
      );
    } else {
      return formattedValue;
    }
  };

  return (
    <div className="">
      <Navbar />
      {loader ? (
        <>
          <div className="flex justify-center  align-items-center p-2  ">
            <svg
              className=" animate-spin h-10 w-10  "
              fill="#014108"
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 512 512"
            >
              <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
            </svg>
          </div>
        </>
      ) : (
        <>
          <div className="border border-black-600"></div>
          <div className="container mx-auto mb-4">
            <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-5 gap-7 justify-center items-center">
              {newData.map((panel) => {
                return (
                  <>
                    <button className=" ">
                      <div
                        onClick={() => {
                          Navigate("/rentdetails");
                        }}
                        className="border rounded-md  cursor-pointer  hover:scale-110   shadow-lg   transition duration-300 ease-in-out "
                      >
                        <div className="relative  ">
                          <div className=" absolute left-0  "></div>
                          {panel.type ? (
                            <>
                              <div className=" flex items-center bg-green-900 text-white  px-2 py-2 left-0 bottom-0 absolute font-bold lg:text-xl  text-sm ">
                                <div className="text-md text-sm font-bold ml-2">
                                  {panel.name}
                                </div>
                              </div>
                            </>
                          ) : null}
                          {panel.image ? (
                            <>
                              <img
                                className=" rounded-t-md cursor-pointer  h-52 w-full"
                                src={panel.image}
                              />
                            </>
                          ) : (
                            <>
                              <img
                                className=" rounded-t-md cursor-pointer  h-52 w-full"
                                src={NoImage}
                              />
                            </>
                          )}
                        </div>
                        <div className="text-left bg-white border border-t leading-4 p-2">
                          <div className=" mr-2 ">
                            <div className="">
                              <div className=" font-semibold py-2 text-lg text-green-800 ">
                                {panel.name}
                              </div>

                              <div className="flex items-center  font-bold">
                                <svg
                                  fill="black"
                                  className="w-5 h-5 "
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 320 512"
                                >
                                  <path d="M0 64C0 46.3 14.3 32 32 32H96h16H288c17.7 0 32 14.3 32 32s-14.3 32-32 32H231.8c9.6 14.4 16.7 30.6 20.7 48H288c17.7 0 32 14.3 32 32s-14.3 32-32 32H252.4c-13.2 58.3-61.9 103.2-122.2 110.9L274.6 422c14.4 10.3 17.7 30.3 7.4 44.6s-30.3 17.7-44.6 7.4L13.4 314C2.1 306-2.7 291.5 1.5 278.2S18.1 256 32 256h80c32.8 0 61-19.7 73.3-48H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H185.3C173 115.7 144.8 96 112 96H96 32C14.3 96 0 81.7 0 64z" />
                                </svg>
                                {formatBudget(panel.budget)}
                                <div className=" lg:text-lg ml-2 text-sm ">
                                  {panel.sqft > 0 ? (
                                    <>
                                      | {panel.sqft} {panel.measureUnit}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                              <div className="flex gap-2 mt-2 items-center">
                                {panel.sector ? (
                                  <>
                                    <div className="flex">
                                      <div className="font-normal text-green-800">
                                        Sector:
                                      </div>
                                      <div>{panel.sector}</div>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                              <div className="flex gap-2 mt-2 items-center">
                                <div>{panel.address}</div>
                              </div>
                              <div className="flex items-center lg:gap-3 gap-3 mt-4">
                                <div className="flex items-center gap-2">
                                  {panel.bedrooms ? (
                                    <img className="w-6" src={Bed} />
                                  ) : null}
                                  <div>{panel.bedrooms} </div>
                                </div>
                                <div className="flex items-center gap-2">
                                  {panel.bathrooms ? (
                                    <img className="w-6" src={Bath} />
                                  ) : null}
                                  <div>{panel.bathrooms} </div>
                                </div>
                                <div className="flex gap-2 items-center">
                                  <img className="w-5" src={panel.varifed} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </button>
                  </>
                );
              })}
            </div>
          </div>
        </>
      )}
      <OurServices />
      <Searching />
      <BottomBar />
    </div>
  );
}
