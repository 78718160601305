import React from "react";
import Navbar from "./navbar";
import BottomBar from "./bottomBar";

export default function NotFound() {
  return (
    <>
    <Navbar/>
      <div className="mt-40 mb-40 text-center font-bold text-2xl">No Data Match</div>
      <BottomBar/>
    </>
  );
}
