import React from "react";
import imageOne from "../assets/img/buy.jpg";
import imageTwo from "../assets/img/rent .jpg";
import { useNavigate } from "react-router-dom";

export default function Search() {
  const navigate = useNavigate();
  return (
    <>
      <div className=" ">
        <div className="flex p-2 lg:gap-10 gap-2 mt-5  justify-center items-center">
          <img
            onClick={() => {
              navigate("/property");
            }}
            className="rounded h-32 md:h-24  cursor-pointer"
            src={imageOne}
          />
          <img
            onClick={() => {
              navigate("/property-for-rent");
            }}
            className="rounded h-32 md:h-24   cursor-pointer"
            src={imageTwo}
          />
        </div>
      </div>
    </>
  );
}
