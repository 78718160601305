import React, { useState } from "react";
import logo from "../../assets/img/logoTwo.jpg";
import Modal from "react-modal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { liveUrl } from "./url";

export default function Navbar() {
  const Navigate = useNavigate();
  const location = useLocation();
  const [click, setClick] = useState(false);
  const [modals, setModals] = useState(false);
  const [activeButton, setActiveButton] = useState("");
  const [activeCommercial, setActiveCommercial] = useState("");
  const [active, setActive] = useState("");
  const [selectedOption, setSelectedOption] = useState("residential");
  const [showData, setShowData] = useState(false);
  const [storedataShow, setStoreDataShow] = useState("");
  const [storedata, setStoreData] = useState({
    phone: "",
  });
  const handleNewData = (e) => {
    setStoreData({ ...storedata, [e.target.name]: e.target.value });
  };
  const storedTitleFromLocalStorage = localStorage.getItem("panelTitle");
  var retrievedToken = localStorage.getItem("token");

  const handleSubmit = () => {
    fetch(`${liveUrl}api/Seller/addSeller`, {
      method: "POST",
      body: JSON.stringify({
        ...storedata,
        propertyType: active,
        residential: activeButton,
        commercial: activeCommercial,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem("responseData", JSON.stringify(data.result));
        setStoreDataShow(data.result);
        setClick(true);
        if (data.status === "done") {
          Navigate("/aboutproperty");
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //function
  const handleLogout = () => {
    localStorage.removeItem("token");
    Navigate("/login");
  };
  const handleClick = (span) => {
    setActiveButton(span);
  };
  const handleCommercial = (span) => {
    setActiveCommercial(span);
  };
  const handleClickButton = (div) => {
    setActive(div);
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const datashow = () => {
    setShowData(true);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "10px",
    },
  };
  return (
    <div className="">
      <div className=" ">
        <Modal
          isOpen={modals}
          onRequestClose={() => setModals(false)}
          style={customStyles}
        >
          <div className="lg:w-[700px]  p-2">
            <div className="flex justify-between">
              <h6 className="text-start text-2xl   font-semibold text-green-600">
                Posting your property is free, so get started.
              </h6>
              <div
                onClick={() => {
                  setModals(false);
                }}
                className="bg-red-600 w-9 h-9 cursor-pointer flex justify-center absolute top-0 right-0 items-center"
              >
                <svg
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 448 512"
                >
                  <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                </svg>
              </div>
            </div>
            <div>
              <p className="text3 mt-3 text-start text-lg font-semibold">
                Provide a few fundamental details
              </p>
            </div>
            <div></div>
            <div className="grid grid-cols-3  justify-center items-center gap-5 mt-3">
              <button
                style={{
                  border: "2px solid #D3D3D3",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  height: "45px",

                  cursor: "pointer",
                }}
                onClick={() => handleClickButton("sale") || datashow()}
                className={
                  active === "sale"
                    ? "activess btn btn-solid "
                    : "btn btn-solid"
                }
              >
                Sell
              </button>
              <button
                style={{
                  border: "2px solid #D3D3D3",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  height: "45px",

                  cursor: "pointer",
                }}
                onClick={() => handleClickButton("Rent/Lease") || datashow()}
                className={
                  active === "Rent/Lease"
                    ? "activess btn btn-solid"
                    : "btn btn-solid"
                }
              >
                Rent/Lease
              </button>
            </div>
            {click && active == "" ? (
              <div className="text-red-600">select any one option</div>
            ) : null}
            {showData ? (
              <>
                <div className="flex gap-5 mt-4">
                  <label className="flex items-center">
                    <input
                      className="w-5 h-5 mr-2 "
                      type="radio"
                      name="residential"
                      value="residential"
                      checked={selectedOption === "residential"}
                      onChange={handleOptionChange}
                    />
                    Residential
                  </label>

                  <label className="flex items-center">
                    <input
                      className="w-5 h-5 mr-2 "
                      type="radio"
                      name="commercial"
                      value="commercial"
                      checked={selectedOption === "commercial"}
                      onChange={handleOptionChange}
                    />
                    Commercial
                  </label>
                </div>
                {selectedOption === "residential" ? (
                  <>
                    <div className="flex items-center gap-2 mt-5 ">
                      <button
                        style={{
                          border: "2px solid #D3D3D3",
                          borderRadius: "10px",
                          backgroundColor: "white",
                        }}
                        onClick={() => handleClick("Flat/Apartment")}
                        className={
                          activeButton === "Flat/Apartment"
                            ? "activess btn btn-solid w-full p-2 text-center"
                            : "btn btn-solid w-full p-2 text-center"
                        }
                      >
                        Flat/Apartment
                      </button>
                      <button
                        style={{
                          border: "2px solid #D3D3D3",
                          borderRadius: "10px",
                          backgroundColor: "white",
                        }}
                        onClick={() => handleClick("IndePendentHouse/villa")}
                        className={
                          activeButton === "IndePendentHouse/villa"
                            ? "activess btn btn-solid w-full p-2 text-center"
                            : "btn btn-solid w-full p-2 text-center"
                        }
                      >
                        IndependentHouse/villa
                      </button>
                    </div>
                    <div className="flex gap-2 justify-center">
                      <div className="mt-3 text-start w-full">
                        <button
                          style={{
                            border: "2px solid #D3D3D3",
                            borderRadius: "10px",
                            backgroundColor: "white",
                          }}
                          onClick={() =>
                            handleClick("Independent/Builder Floor")
                          }
                          className={
                            activeButton === "Independent/Builder Floor"
                              ? "activess btn btn-solid w-full p-2 text-center"
                              : "btn btn-solid w-full p-2 text-center"
                          }
                        >
                          Independent/Builder Floor
                        </button>
                      </div>
                      <div className="mt-3 text-start w-full">
                        <button
                          style={{
                            border: "2px solid #D3D3D3",
                            borderRadius: "10px",
                            backgroundColor: "white",
                          }}
                          onClick={() => handleClick("plot")}
                          className={
                            activeButton === "plot"
                              ? "activess btn btn-solid w-full p-2 text-center"
                              : "btn btn-solid w-full p-2 text-center"
                          }
                        >
                          Plot/Land
                        </button>
                      </div>
                    </div>
                    <div className="flex gap-2 mt-3">
                      <button
                        style={{
                          border: "2px solid #D3D3D3",
                          borderRadius: "10px",
                          backgroundColor: "white",
                        }}
                        onClick={() => handleClick("1RK/Studio Apartment")}
                        className={
                          activeButton === "1RK/Studio Apartment"
                            ? "activess btn btn-solid w-full p-2 text-center"
                            : "btn btn-solid w-full p-2 text-center"
                        }
                      >
                        1RK/Studio Apartment
                      </button>
                      <button
                        style={{
                          border: "2px solid #D3D3D3",
                          borderRadius: "10px",
                          backgroundColor: "white",
                        }}
                        onClick={() => handleClick("Serviced Apartment")}
                        className={
                          activeButton === "Serviced Apartment"
                            ? "activess btn btn-solid w-full p-2 text-center"
                            : "btn btn-solid w-full p-2 text-center"
                        }
                      >
                        Serviced Apartment
                      </button>
                    </div>
                    <div className="flex gap-2 mt-3">
                      <button
                        style={{
                          border: "2px solid #D3D3D3",
                          borderRadius: "10px",
                          backgroundColor: "white",
                        }}
                        onClick={() => handleClick("Farmhouse")}
                        className={
                          activeButton === "Farmhouse"
                            ? "activess btn btn-solid w-full p-2 text-center"
                            : "btn btn-solid w-full p-2 text-center"
                        }
                      >
                        Farmhouse
                      </button>
                      <button
                        style={{
                          border: "2px solid #D3D3D3",
                          borderRadius: "10px",
                          backgroundColor: "white",
                        }}
                        onClick={() => handleClick("Other")}
                        className={
                          activeButton === "Other"
                            ? "activess btn btn-solid w-full p-2 text-center"
                            : "btn btn-solid w-full p-2 text-center"
                        }
                      >
                        Other
                      </button>
                    </div>
                    {click && activeButton == "" ? (
                      <div className="text-red-600">select any one option</div>
                    ) : null}
                  </>
                ) : null}
                {selectedOption === "commercial" ? (
                  <>
                    <div>
                      <div className="flex gap-5  mt-3">
                        <button
                          style={{
                            border: "2px solid #D3D3D3",
                            width: "100px",
                            backgroundColor: "white",
                          }}
                          onClick={() => handleCommercial("Office")}
                          className={
                            activeCommercial === "Office"
                              ? "activess btn btn-solid w-full p-2 text-center"
                              : "btn btn-solid w-full p-2 text-center"
                          }
                        >
                          Office
                        </button>
                        <button
                          style={{
                            border: "2px solid #D3D3D3",

                            backgroundColor: "white",
                            fontSize: "15px",

                            cursor: "pointer",
                          }}
                          onClick={() => handleCommercial(" Retail")}
                          className={
                            activeCommercial === " Retail"
                              ? "activess btn btn-solid w-full p-2 text-center"
                              : "btn btn-solid w-full p-2 text-center"
                          }
                        >
                          Retail
                        </button>
                        <button
                          style={{
                            border: "2px solid #D3D3D3",

                            backgroundColor: "white",
                            fontSize: "15px",

                            cursor: "pointer",
                          }}
                          onClick={() => handleCommercial("Plot/Land")}
                          className={
                            activeCommercial === "Plot/Land"
                              ? "activess btn btn-solid w-full p-2 text-center"
                              : "btn btn-solid w-full p-2 text-center"
                          }
                        >
                          Plot/Land
                        </button>
                        <button
                          style={{
                            border: "2px solid #D3D3D3",

                            backgroundColor: "white",
                            fontSize: "15px",

                            cursor: "pointer",
                          }}
                          onClick={() => handleCommercial("Storage")}
                          className={
                            activeCommercial === "Storage"
                              ? "activess btn btn-solid w-full p-2 text-center"
                              : "btn btn-solid w-full p-2 text-center"
                          }
                        >
                          Storage
                        </button>
                      </div>
                      <div className="flex gap-5 mt-3">
                        <button
                          style={{
                            border: "2px solid #D3D3D3",

                            backgroundColor: "white",
                            fontSize: "15px",

                            cursor: "pointer",
                          }}
                          onClick={() => handleCommercial("Industry")}
                          className={
                            activeCommercial === "Industry"
                              ? "activess btn btn-solid w-full p-2 text-center"
                              : "btn btn-solid w-full p-2 text-center"
                          }
                        >
                          Industry
                        </button>
                        <button
                          style={{
                            border: "2px solid #D3D3D3",

                            backgroundColor: "white",
                            fontSize: "15px",

                            cursor: "pointer",
                          }}
                          onClick={() => handleCommercial("Hospitality")}
                          className={
                            activeCommercial === "Hospitality"
                              ? "activess btn btn-solid w-full p-2 text-center"
                              : "btn btn-solid w-full p-2 text-center"
                          }
                        >
                          Hospitality
                        </button>
                        <button
                          style={{
                            border: "2px solid #D3D3D3",

                            backgroundColor: "white",
                            fontSize: "15px",

                            cursor: "pointer",
                          }}
                          onClick={() => handleCommercial("Other")}
                          className={
                            activeCommercial === "Other"
                              ? "activess btn btn-solid w-full p-2 text-center"
                              : "btn btn-solid w-full p-2 text-center"
                          }
                        >
                          Other
                        </button>
                      </div>
                    </div>
                    {click && activeCommercial == "" ? (
                      <div className="text-red-600">select any one option</div>
                    ) : null}
                  </>
                ) : null}
              </>
            ) : null}
            <div>
              <h6 className="mt-3 text-lg font-bold">
                Your contact information so the buyer can get in touch with you
              </h6>
            </div>
            <div className="text-start ms-3">
              <input
                value={storedata.phone}
                name="phone"
                onChange={handleNewData}
                className="h-10 rounded p-2 border border-black w-full"
                type="number"
                placeholder="Phone Number"
                maxLength={10}
              />
            </div>
            {click && storedata.phone.length < 10 ? (
              <div className="text-red-600">Phone Number not valid</div>
            ) : null}
            {click && storedata.phone.length > 10 ? (
              <div className="text-red-600">Phone Number not valid</div>
            ) : null}

            {retrievedToken ? (
              <>
                <div></div>
              </>
            ) : (
              <>
                <p className=" mt-2 text-start  font-bold mb-2">
                  Are you a registered user ?{" "}
                  <Link to="/login" className="text5 text-green-600 ">
                    Login
                  </Link>
                </p>
              </>
            )}
            <button
              onClick={handleSubmit}
              className="bg-red-600 w-full p-2 mt-5 rounded flex justify-center items-center"
            >
              <div className=" text-white font-bold rounded-xl">Submit</div>
            </button>
          </div>
        </Modal>
        <div className="relative">
          <div className=" w-full bg-white z-50">
            <div className="container mx-auto">
              <div className=" ">
                <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 sm:grid-cols-1 justify-center items-center">
                  <div className="flex justify-center lg:justify-start">
                    <Link to="/">
                      <img
                        className="w-1/1 p-2 "
                        src="https://bestpropertiesmohali.com/assets/images/logo1.png"
                      />
                     
                    </Link>
                  </div>

                  <div className="flex lg:ml-2 mb-2 mt-2 lg:gap-5 gap-5 justify-center items-center">
                    <button
                      onClick={() => {
                        Navigate("/property");
                      }}
                      className={`hover:bg-green-600 text-green-800 p-1 lg:py-2 lg:px-4 ${
                        location.pathname === "/property" ? "bg-green-600" : ""
                      }`}
                    >
                      Properties
                    </button>
                    <Link
                      to="/buyer-data"
                      className={`hover:bg-green-600 text-green-800 p-1 lg:py-2 lg:px-4 ${
                        location.pathname === "/buyer-data"
                          ? "bg-green-600"
                          : ""
                      }`}
                    >
                      Buy
                    </Link>
                    <button
                      onClick={() => {
                        Navigate("/property-for-rent");
                      }}
                      className={`hover:bg-green-600 text-green-800 p-1 lg:py-2 lg:px-4 ${
                        location.pathname === "/property-for-rent"
                          ? "bg-green-600"
                          : ""
                      }`}
                    >
                      Rent
                    </button>
                    <Link
                      onClick={() => {
                        setModals(true);
                      }}
                      className={`hover:bg-green-600 text-green-800 lg:py-2 p-1 lg:px-4 ${
                        location.pathname === "" ? "bg-green-600" : ""
                      }`}
                    >
                      Sell With Us
                    </Link>

                    {retrievedToken ? (
                      <>
                        <div
                          onClick={() => {
                            Navigate("/Agent");
                          }}
                          className="text-green-800 lg:block hidden cursor-pointer font-leading"
                        >
                          {storedTitleFromLocalStorage}
                        </div>
                      </>
                    ) : null}
                    <div>
                      {retrievedToken ? (
                        <>
                          <button
                            className="text-red-600 font-leading"
                            onClick={handleLogout}
                          >
                            Logout
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="text-red-600 font-leading"
                            onClick={() => {
                              Navigate("/login");
                            }}
                          >
                            Login
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border border-black-400"></div>
      </div>
    </div>
  );
}
