import React from "react";
import Navbar from "./navbar";
import BottomBar from "./bottomBar";

export default function TermandCondition() {
  return (
    <div>
      <Navbar />
      <div className="border border-black-600"></div>
      <div className="text-center mt-52 mb-52"> Coming Soon...</div>
      <BottomBar />
    </div>
  );
}
