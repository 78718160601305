import React from "react";
import GalleryComponent from "./galleryComponent";
import AnimatedText from "./HeadingAnimation";

export default function Home() {

  return (
    <div className="  ">
      <div className="container  px-6 mx-auto mt-5 ">
        <div className="">
          <section className=" ">
            <div className="flex justify-center items-center">
              <div>
                <h2 className="lg:text-3xl text-2xl font-bold text-center text-green-900 mt-3">
                  <AnimatedText text="HOT DEALS" />
                </h2>
              </div>
            </div>
            <GalleryComponent />
          </section>
        </div>
      </div>
    </div>
  );
}
