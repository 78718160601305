import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Bed from "../../assets/img/bed.png";
import Bath from "../../assets/img/bath.png";
import Modal from "react-modal";
import NoImage from "../../assets/img/image-not.jpg";
import { liveUrl, token } from "./url";
import Rent from "../../Component/rent";
export default function GalleryComponent() {
  const Navigate = useNavigate();
  const [newData, setNewData] = useState([]);
  const [showCount, setShowCount] = useState(8);
  const [id, setId] = useState([]);
  const [modals, setModals] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showRent, setShowRent] = useState(false);
  const handleShowMore = () => {
    setShowCount(showCount + 8);
  };
  const ref = useRef(null);
  const handleClick = (event) => {
    setId(event.currentTarget.id);
  };
  const handleSubmit = () => {
    setLoader(true);
    fetch(`${liveUrl}api//Reactjs/gallery`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Add any other headers you need
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.result, "this is response");
        setNewData(data.result);
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    handleSubmit();
  }, []);
  const formatBudget = (value) => {
    const formattedValue = value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    if (value >= 10000000) {
      return (
        (value / 10000000).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) + " Crore"
      );
    } else if (value >= 100000) {
      return (
        (value / 100000).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) + " Lac"
      );
    } else if (value >= 1000) {
      return (
        (value / 1000).toLocaleString(undefined, { minimumFractionDigits: 2 }) +
        " Thousand"
      );
    } else {
      return formattedValue;
    }
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "10px",
    },
  };
  return (
    <div>
      {loader ? (
        <>
          <div className="flex justify-center  align-items-center p-2  ">
            <svg
              className=" animate-spin h-10 w-10  "
              fill="#014108"
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 512 512"
            >
              <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
            </svg>
          </div>
        </>
      ) : (
        <>
          {showRent ? (
            <>
              <Rent />
            </>
          ) : (
            <>
              <div className="grid lg:grid-cols-4  md:grid-cols-2 grid-cols-1 mt-5 gap-7 justify-center items-center">
                {newData.slice(0, showCount).map((panel) => {
                  return (
                    <>
                      <button className=" ">
                        <div>
                          <Modal
                            isOpen={modals}
                            onRequestClose={() => setModals(false)}
                            style={customStyles}
                          >
                            <div className="text-center font-bold text-2xl">
                              Gallery Image
                            </div>
                            <div className="grid lg:grid-cols-3  px-4 justify-center">
                              {panel.image.length > 0 ? (
                                <>
                                  <img
                                    src={panel.image}
                                    className="lg:w-full bg-cover  w-52   p-1"
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    className="lg:w-full w-52"
                                    src={NoImage}
                                  />
                                </>
                              )}
                            </div>
                          </Modal>
                        </div>
                        <div
                          onClick={() => {
                            const modifiedPanelName = panel.name
                              .replace(/\s/g, "-")
                              .toLowerCase();
                            Navigate(
                              `/property/-${panel.id}-${modifiedPanelName}`
                            );
                          }}
                          className="border rounded-md  cursor-pointer  hover:scale-110   shadow-lg   transition duration-300 ease-in-out "
                        >
                          <div className="relative  ">
                            <div className=" absolute left-0  "></div>
                            {panel.type ? (
                              <>
                                <div className=" flex items-center bg-green-900 text-white  px-2 py-2 left-0 bottom-0 absolute font-bold lg:text-xl  text-sm ">
                                  <div className="text-md text-sm font-bold ml-2">
                                    {panel.name}
                                  </div>
                                </div>
                              </>
                            ) : null}
                            <img
                              className=" rounded-t-md cursor-pointer  h-52 w-full"
                              src={panel.image}
                            />
                          </div>
                          <div className="text-left bg-white border border-t leading-4 p-2">
                            <div className=" mr-2 ">
                              <div className="">
                                <div className="text-sm font-extralight ">
                                  {panel.property_name}
                                </div>

                                <div className="flex items-center text-green-800 font-bold">
                                  <svg
                                    fill="#14532D"
                                    className="w-5 h-5 "
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 320 512"
                                  >
                                    <path d="M0 64C0 46.3 14.3 32 32 32H96h16H288c17.7 0 32 14.3 32 32s-14.3 32-32 32H231.8c9.6 14.4 16.7 30.6 20.7 48H288c17.7 0 32 14.3 32 32s-14.3 32-32 32H252.4c-13.2 58.3-61.9 103.2-122.2 110.9L274.6 422c14.4 10.3 17.7 30.3 7.4 44.6s-30.3 17.7-44.6 7.4L13.4 314C2.1 306-2.7 291.5 1.5 278.2S18.1 256 32 256h80c32.8 0 61-19.7 73.3-48H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H185.3C173 115.7 144.8 96 112 96H96 32C14.3 96 0 81.7 0 64z" />
                                  </svg>
                                  {formatBudget(panel.budget)}
                                  <div className=" lg:text-lg ml-2 text-sm ">
                                    {panel.sqft > 0 ? (
                                      <>
                                        | {panel.sqft} {panel.measureUnit}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                                <div className="flex gap-2  mt-2 items-center">
                                  <div>
                                    <svg
                                      className="h-5 w-5"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 384 512"
                                    >
                                      <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                                    </svg>
                                  </div>
                                  <div>{panel.address}</div>
                                </div>
                                <div className="flex items-center lg:gap-3 gap-3 mt-4">
                                  <div className="flex items-center gap-2">
                                    {panel.bedrooms ? (
                                      <img className="w-6" src={Bed} />
                                    ) : null}
                                    <div className="font-semibold text-green-800">
                                      {panel.bedrooms}
                                    </div>
                                  </div>
                                  <div className="flex items-center gap-2">
                                    {panel.bathrooms ? (
                                      <img className="w-6" src={Bath} />
                                    ) : null}
                                    <div className="font-semibold text-green-800">
                                      {panel.bathrooms}
                                    </div>
                                  </div>
                                  <div className="flex gap-2 items-center">
                                    <img className="w-5" src={panel.varifed} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </button>
                    </>
                  );
                })}
                {/* <div className="lg:block md:hidden sm:hidden"></div> */}
              </div>
              <div className="flex justify-center mt-10 mb-14">
                {showCount < newData.length && (
                  <button
                    className="font-bold p-2 w-52 rounded-md text-white bg-red-600"
                    onClick={handleShowMore}
                  >
                    Show More
                  </button>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
